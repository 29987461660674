'use client';

import { useRouter } from 'next/navigation';

import { type Props } from '~/app/error';
import { isEngage } from '~/global/global.constants';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { NextJsTextLink } from '~/components/Buttons/Components/NextJsTextLink';

type ErrorWrapperProps = Props & {
	showStackTrace: boolean
	inline?: boolean
};

export const ErrorWrapper = (props: ErrorWrapperProps) => {
	const router = useRouter();
	const {
		error, showStackTrace, reset,
	} = props;
	const inline = props.inline === undefined ? true : props.inline;

	return (
		<div className="tw-mt-4 tw-pb-20 tw-px-4 print:tw-pb-0">
			<div className="md:tw-divide-y md:tw-divide-gray-400 print:tw-divide-y-0">
				{
					!inline &&
					<header className="tw-grid tw-grid-cols-12 tw-pb-3 md:tw-items-start print:tw-pb-0 print:tw-grid-cols-8">
						<div className="tw-col-span-8 tw-col-start-3 tw-text-center print:tw-flex print:tw-justify-between print:tw-col-start-auto print:tw-items-center print:tw-border-b-2 print:tw-border-gray-100 print:tw-pb-4">
							<a className="tw-inline-block" href="/">
								<img
									className="tw-h-6 md:tw-h-12 print:tw-h-12"
									src="https://rnb.scene7.com/is/content/roomandboard/rnb-logo-black"
									alt="Room & Board - Homepage."
								/>
							</a>
							<div className="tw-hidden print:tw-block tw-text-right tw-text-sm">
								<div>We're Here To Help</div>
								<div>800.301.9720</div>
							</div>
						</div>
					</header>
				}
				<main>
					<div className="full-width-layout md:tw-relative tw-pb-4">
						<div className="tw-pt-2 tw-space-y-4 md:tw-pt-4 md:tw-space-y-0 md:tw-grid md:tw-grid-cols-12 md:tw-gap-4 print:tw-py-0 print:md:tw-w-full tw-flex-grow tw-pb-24 md:tw-pb-16">
							<div className="tw-col-span-12">
								<div className="tw-px-1 tw-pb-8 tw-space-y-8 md:tw-py-0">
									<h1 className="tw-text-lg tw-mt-1 tw-font-light md:tw-text-xl print:tw-hidden">
										An error has occurred
									</h1>
									{
										showStackTrace &&
										<pre className="tw-font-mono tw-overflow-auto">
											<div className="tw-font-bold tw-text-red tw-pb-16">{error.message}</div>
											{error.stack}
										</pre>
									}
									{
										isEngage
											?
											<p>
												<TextButton
													type="button"
													onClick={() => {
														if (reset) {
															reset();
															return;
														}
														router.refresh();
													}}
												>
													Try again
												</TextButton>
												, or if problem persists, please open a Help Desk ticket.
											</p>
											:
											<p>
												If you’d like assistance from a Design Associate, contact us by phone
												at{' '}
												<a href="tel:+18003019720">800.301.9720</a>{' '}
												or by email at{' '}
												<a href="mailto:shop@roomandboard.com">shop@roomandboard.com</a>.
											</p>
									}
									<p>
										<NextJsTextLink href="/">
											Return to Homepage
										</NextJsTextLink>
									</p>
								</div>
							</div>
						</div>
					</div>
				</main>
				{
					!inline &&
					<footer className="tw-border-t tw-border-solid tw-border-gray-400 tw-block tw-pt-4 tw-text-gray-300 tw-text-xs tw-space-y-4 md:tw-text-sm md:tw-space-y-0 md:tw-flex md:tw-justify-between md:tw-mt-12 print:tw-hidden">
						<div className="tw-space-y-2">
							<div>
								© 2007-{new Date().getUTCFullYear()} Room & Board. All Rights Reserved.
								<span className="tw-hidden md:tw-inline"> Modern Furniture</span>
							</div>
						</div>
						<ul className="tw-flex tw-flex-wrap">
							<li>
								<a href="/privacy/" className="tw-text-gray-300 tw-no-underline" target="_blank">
									Privacy Policy
								</a>
							</li>
							<li className="before:tw-content-['|'] before:tw-inline-block before:tw-mx-2">
								<a href="/hr-privacy/" className="tw-text-gray-300 tw-no-underline" target="_blank">
									HR Privacy Policy
								</a>
							</li>
							<li className="before:tw-content-['|'] before:tw-inline-block before:tw-mx-2">
								<a href="/terms-of-use/" className="tw-text-gray-300 tw-no-underline" target="_blank">
									Terms of Use
								</a>
							</li>
							<li className="before:tw-content-['|'] before:tw-inline-block before:tw-mx-2">
								<a href="/dmca/" className="tw-text-gray-300 tw-no-underline" target="_blank">DMCA</a>
							</li>
							<li className="before:tw-content-['|'] before:tw-inline-block before:tw-mx-2">
								<a href="/accessibility" className="tw-text-gray-300 tw-no-underline" target="_blank">
									Accessibility
								</a>
							</li>
						</ul>
					</footer>
				}
			</div>
		</div>
	);
};
